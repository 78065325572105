<template>
  <v-app>
    <div>
      <div class="row align-items-center justify-content-end mt-n3">
        <div class="col-md-8 col-12">
          <button
            class="btn mx-1 btn-timeseries"
            style="
              padding-left: 4px;
              padding-right: 4px;
              padding-top: 5px;
              padding-bottom: 5px;
            "
            @click="btnActiveOnClick('days')"
            v-bind:class="{
              'btn-info': btnActive.days,
              'btn-outline-info': btnActive.days == false,
            }"
          >
            Hari Ini
          </button>
          <button
            class="btn mx-1 btn-timeseries"
            style="
              padding-left: 4px;
              padding-right: 4px;
              padding-top: 5px;
              padding-bottom: 5px;
            "
            @click="btnActiveOnClick('month')"
            v-bind:class="{
              'btn-info': btnActive.month,
              'btn-outline-info': btnActive.month == false,
            }"
          >
            Bulan Ini
          </button>
          <button
            class="btn mx-1 btn-timeseries"
            style="
              padding-left: 4px;
              padding-right: 4px;
              padding-top: 5px;
              padding-bottom: 5px;
            "
            @click="btnActiveOnClick('lastMonth')"
            v-bind:class="{
              'btn-info': btnActive.lastMonth,
              'btn-outline-info': btnActive.lastMonth == false,
            }"
          >
            Bulan Kemarin
          </button>
          <button
            class="btn mx-1 btn-timeseries"
            style="
              padding-left: 4px;
              padding-right: 4px;
              padding-top: 5px;
              padding-bottom: 5px;
            "
            @click="btnActiveOnClick('year')"
            v-bind:class="{
              'btn-info': btnActive.year,
              'btn-outline-info': btnActive.year == false,
            }"
          >
            Tahun Ini
          </button>
          <button
            class="btn mx-1 btn-timeseries"
            style="
              padding-left: 4px;
              padding-right: 4px;
              padding-top: 5px;
              padding-bottom: 5px;
            "
            v-bind:class="{
              'btn-info': btnActive.period,
              'btn-outline-info': btnActive.period == false,
            }"
            @click="btnPeriodeOnClick"
          >
            Periode
          </button>
        </div>
        <div class="col-md-4 col-12">
          <b-input-group v-if="source == 0">
            <b-form-input
              type="text"
              v-model="display.patient_name"
              placeholder="Cari Berdasarkan Pasien"
              readonly
              @click="$bvModal.show('modal-patient')"
            ></b-form-input>
            <template #append>
              <b-button squared @click="pagination" variant="success"
                >Cari</b-button
              >
              <b-button squared @click="resetFilter" variant="danger"
                >Reset</b-button
              >
            </template>
          </b-input-group>
        </div>
      </div>

      <!-- timeseries -->
      <div class="row justify-content-start align-items-center mt-3">
        <div class="col-md-2">
          <span
            v-if="totalRows != null"
            style="width: 150px; color: #245590 !important"
            class="font-weight-bold"
            >{{ totalRows }} Baris Data</span
          >
        </div>
        <div class="col-md-8" v-if="onShowPeriod">
          <span class="d-none d-sm-block">
            <b-input-group>
              <b-form-input
                v-model="filter.start_date"
                type="text"
                placeholder="Awal"
                autocomplete="off"
                readonly
              ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  v-model="filter.start_date"
                  button-only
                  right
                  reset-button
                  locale="id"
                  aria-controls="example-input"
                ></b-form-datepicker>
              </b-input-group-append>
              <b-button squared variant="light">s/d</b-button>
              <b-form-input
                v-model="filter.end_date"
                type="text"
                placeholder="Akhir"
                autocomplete="off"
                readonly
              ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  v-model="filter.end_date"
                  button-only
                  right
                  reset-button
                  locale="id"
                  aria-controls="example-input"
                ></b-form-datepicker>
              </b-input-group-append>
              <template #append>
                <b-button squared @click="pagination" variant="success"
                  >Cari</b-button
                >
                <b-button squared @click="resetFilter" variant="danger"
                  >Reset</b-button
                >
              </template>
            </b-input-group>
          </span>
          <span class="d-block d-sm-none">
            <div class="row">
              <div class="col-12 pb-1">
                <b-input-group>
                  <b-form-input
                    v-model="filter.start_date"
                    type="text"
                    placeholder="Awal"
                    autocomplete="off"
                    readonly
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="filter.start_date"
                      button-only
                      right
                      reset-button
                      locale="id"
                      aria-controls="example-input"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div class="col-12 pt-0">
                <b-input-group>
                  <b-button squared variant="light">s/d</b-button>
                  <b-form-input
                    v-model="filter.end_date"
                    type="text"
                    placeholder="Akhir"
                    autocomplete="off"
                    readonly
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="filter.end_date"
                      button-only
                      right
                      reset-button
                      locale="id"
                      aria-controls="example-input"
                    ></b-form-datepicker>
                  </b-input-group-append>
                  <template #append>
                    <b-button squared @click="pagination" variant="success"
                      >Cari Periode</b-button
                    >
                    <b-button squared @click="resetFilter" variant="danger"
                      >Reset Periode</b-button
                    >
                  </template>
                </b-input-group>
              </div>
            </div>
          </span>
        </div>
      </div>

      <b-table
        hover
        class="mt-3"
        responsive
        :items="items"
        :fields="fields"
        thead-class="bg-secondary"
      >
        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.key === 'actions' ? '20%' : '' }"
          />
        </template>
        <!-- detail collapse -->
        <template #cell(price)="data">
          {{ parseInt(data.item.price).toLocaleString("id-ID") }}
        </template>
        <template #cell(expired_at)="data">
          <span v-if="data.item.diff_exp_and_today < 0">{{
            data.item.expired_at +
            " (" +
            Math.abs(data.item.diff_exp_and_today) +
            " Hari Lagi)"
          }}</span>
          <span v-if="data.item.diff_exp_and_today > 0">{{
            data.item.expired_at +
            " (" +
            Math.abs(data.item.diff_exp_and_today) +
            " Hari Lalu)"
          }}</span>
          <span v-if="data.item.diff_exp_and_today == 0">{{
            data.item.expired_at + " (Hari Ini)"
          }}</span>
        </template>
        <template #cell(status)="data">
          <b-badge
            pill
            variant="primary"
            class="mx-1"
            v-if="
              data.item.usage_status == 'Belum Digunakan' &&
              data.item.diff_exp_and_today <= 0
            "
          >
            {{ data.item.usage_status }}</b-badge
          >
          <b-badge
            pill
            variant="warning"
            class="mx-1"
            v-if="
              data.item.usage_status == 'Digunakan Sebagian' &&
              data.item.diff_exp_and_today <= 0
            "
          >
            {{ data.item.usage_status }}</b-badge
          >
          <b-badge
            pill
            variant="success"
            class="mx-1"
            v-if="
              data.item.usage_status == 'Digunakan Semua'
            "
          >
            {{ data.item.usage_status }}</b-badge
          >
          <b-badge
            pill
            variant="danger"
            v-if="data.item.usage_status != 'Digunakan Semua' && data.item.diff_exp_and_today > 0"
          >
            Expired</b-badge
          >
        </template>
        <template #row-details="data">
          <div class="card bg-yellow">
            <div class="card-title px-4 py-2 mb-2">
              <span><b>Daftar Item Layanan</b></span>
            </div>
            <div class="card-body px-4 py-2">
              <table class="table">
                <tr>
                  <th>#</th>
                  <th>Nama Layanan</th>
                  <th>Status</th>
                  <th></th>
                </tr>
                <tr v-for="(val, index) in data.item.detail_item" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ val.payment_category_name }}</td>
                  <td>
                    <span>
                      <b>{{ val.usage_total }}</b> dari
                      <b>{{ val.payment_category_quantity }}</b> Terpakai
                    </span>
                  </td>

                  <td>
                    <b-button
                      size="sm"
                      class="mr-1 btn-success"
                      v-b-tooltip.hover
                      title="Ubah Status Pemakaian"
                      v-if="
                        val.usage_total < val.payment_category_quantity &&
                        data.item.diff_exp_and_today <= 0
                      "
                      @click="
                        changeStatus(
                          val.id,
                          val.payment_category_name,
                          val.payment_category_quantity - val.usage_total,
                          val.payment_category_id
                        )
                      "
                      ><i class="fas fa-edit px-0"></i
                    ></b-button>
                    <!-- add duration expired in days -->

                    <b-button
                      size="sm"
                      class="mr-1 btn-info"
                      v-b-tooltip.hover
                      title="Detail Pemakaian"
                      v-if="val.usage_total >= 1"
                      @click="detailItem(val.id, val.payment_category_name)"
                      ><i class="fas fa-eye px-0"></i
                    ></b-button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </template>
        <template #cell(action)="data">
          <b-button
            size="sm"
            class="mr-1 btn-info"
            v-b-tooltip.hover
            title="Info"
            @click="data.toggleDetails"
            ><i class="fas fa-sitemap px-0"></i
          ></b-button>
          <b-button
            size="sm"
            class="mr-1 btn-warning"
            v-b-tooltip.hover
            title="Perpanjang"
            @click="addDuration(data.item)"
          >
            <i class="fas fa-calendar-day px-0"></i>
          </b-button>
        </template>
        <!-- end detail collapse -->
      </b-table>
      <b-pagination
        v-if="items.length != 0"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        aria-controls="my-table"
        @page-click="pageOnClick"
        class="mt-4"
      ></b-pagination>

      <!-- modal update status -->
      <b-modal
        id="modal-status"
        hide-footer
        size="lg"
        :title="'Masukkan Detail Pemakaian - ' + display.service_name"
      >
        <b-form>
          <div class="row">
            <div class="col-md-12 py-0 my-0">
              <span class="mt-2 font-weight-bold"
                >Sisa pemakaian : {{ display.usage_total }}x
              </span>
            </div>
          </div>

          <!-- doctor and nurse -->
          <div class="row py-2">
            <div class="col-md-6">
              <!-- Input Doctor -->
              <b-form-group
                id="input-group-doctor"
                label="Dokter:"
                label-for="input-doctor"
              >
                <treeselect
                  v-model="form.doctor_id"
                  :multiple="false"
                  :options="doctors"
                  placeholder="Pilih Dokter"
                />
                <small class="text-danger">{{ error.doctor_id }}</small>
              </b-form-group>
            </div>

            <!-- input nurse -->
            <div class="col-md-6">
              <b-form-group id="input-group-nurse">
                <label for="input-nurse-notes"
                  >Pegawai : <em class="text-muted">opsional</em></label
                >
                <treeselect
                  v-model="form.nurse_id"
                  :multiple="true"
                  :options="nurses"
                  placeholder="Pilih Pegawai "
                />
                <small class="text-danger">{{ error.nurse_id }}</small>
              </b-form-group>
            </div>
          </div>

          <!-- usage amount -->
          <b-form-group
            id="input-group-used"
            label="Jumlah Pemakaian:"
            label-for="input-used"
          >
            <treeselect
              v-model="form.used_amount"
              :options="amountData"
              placeholder="Pilih Jumlah"
            />
            <small class="text-danger">{{ error.used_amount }}</small>
          </b-form-group>

          <!-- Input Date -->
          <!-- <b-form-group
            id="input-group-date"
            label="Tanggal Pemakaian:"
            label-for="input-date"
          >
            <v-dialog
              ref="dialog"
              v-model="modal2"
              :return-value.sync="form.used_date"
              persistent
              width="350px"
            >
              <template v-slot:activator="{ on, attrs }">
                <b-form-input
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  id="input-trigger-modal-date"
                  v-model="form.used_date"
                >
                </b-form-input>
              </template>

              <v-date-picker v-if="modal2" locale="id" v-model="form.used_date">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal2 = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(form.used_date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>

            <small class="text-danger">{{ error.used_date }}</small>
          </b-form-group> -->

          <b-form-group
            id="input-group-birth-date"
            label="Tanggal Pemakaian:"
            label-for="input-sip-birth-date"
          >
            <b-input-group>
              <div class="row">
                <div class="col-md-12">
                  <v-dialog
                    ref="dialog"
                    v-model="modal2"
                    :return-value.sync="form.used_date"
                    persistent
                    width="350px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <b-input-group>
                        <b-input-group-prepend>
                          <button
                            class="btn btn-secondary"
                            type="button"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <i class="fas fa-calendar"></i>
                          </button>
                        </b-input-group-prepend>
                        <b-form-input
                          id="input-trigger-modal-patient"
                          v-model="form.used_date"
                          placeholder="(YYYY-MM-DD)"
                          @input="formaterDate"
                        >
                        </b-form-input>
                      </b-input-group>
                    </template>

                    <v-date-picker
                      v-if="modal2"
                      v-model="form.used_date"
                      locale="id"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal2 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(form.used_date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </div>
              </div>
            </b-input-group>

            <small class="text-danger">{{ error.used_date }}</small>
          </b-form-group>

          <!-- Notes input -->
          <b-form-group id="input-group-nurse-notes">
            <label for="input-nurse-notes"
              >Keterangan : <em class="text-muted">opsional</em></label
            >
            <b-form-textarea
              id="input-nurse-notes"
              v-model="form.notes"
              placeholder="Keterangan"
              rows="4"
              max-rows="8"
            ></b-form-textarea>
            <small class="text-danger">{{ error.notes }}</small>
          </b-form-group>

          <div class="row">
            <div class="col-md-12 mb-4 pt-0 mt-0">
              <span class="mt-2">
                <i class="fas fa-info-circle text-primary"></i> Note : Pemberian
                komisi berdasarkan dokter dan pegawai yang dipilih</span
              >
            </div>
          </div>

          <b-button type="button" @click="saveDetail" variant="primary"
            >Simpan</b-button
          >
          <b-button
            type="button"
            class="ml-2"
            variant="default"
            @click="hideModalCancel"
          >
            Cancel
          </b-button>
        </b-form>
      </b-modal>

      <b-modal
        id="modal-detail"
        hide-footer
        size="xl"
        :title="'Detail Pemakaian - ' + display.service_name"
      >
        <div class="row">
          <div class="col-md-6 col-12">
            <table class="table table-borderless">
              <tr>
                <!-- name -->
                <td class="font-weight-bold">Nama Layanan</td>
                <td class="font-weight-bold">: {{ display.service_name }}</td>
              </tr>
              <tr>
                <!-- quantity -->
                <td class="font-weight-bold">Jumlah Tindakan</td>
                <td class="font-weight-bold">
                  : {{ dataDetail.payment_category_quantity }}
                </td>
              </tr>

              <tr>
                <!-- price -->
                <td class="font-weight-bold">Jumlah Digunakan</td>
                <td class="font-weight-bold">
                  :
                  {{
                    display.remaining_usage +
                    " dari " +
                    dataDetail.payment_category_quantity
                  }}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <table class="table">
              <tr>
                <th>Tanggal Pemakaian</th>
                <th>Dokter (komisi)</th>
                <th>Pegawai (komisi)</th>
              </tr>
              <tr v-for="(val, index) in dataDetail.payment_usage" :key="index">
                <!-- date -->
                <!-- <td class="font-weight-bold">Tanggal Penggunaan</td>
                  <td class="font-weight-bold">:</td> -->
                <td>
                  {{
                    new Date(val.usage_date)
                      .toLocaleString("id-ID", {
                        weekday: "long",
                        day: "numeric",
                        month: "numeric",
                        year: "numeric",
                      })
                      .replaceAll("/", "-")
                  }}
                </td>

                <!-- doctor -->
                <!-- <td class="font-weight-bold">Dokter(Komisi)</td>
                  <td class="font-weight-bold">:</td> -->
                <td>
                  {{ val.doctor.name + "-" + val.doctor.polyclinic_name }} ({{
                    parseInt(val.doctor_commission).toLocaleString("id-ID")
                  }})
                </td>

                <!-- pegawai -->
                <!-- <td class="font-weight-bold">Pegawai/td>
                  <td class="font-weight-bold">:</td> -->
                <td>
                  <ul>
                    <li
                      v-for="(dat, ind) in val.employee_commission"
                      :key="ind"
                    >
                      {{ dat.employee.name }} ({{
                        parseInt(dat.employee_commission_amout).toLocaleString(
                          "id-ID"
                        )
                      }})
                    </li>
                  </ul>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </b-modal>

      <b-modal
        id="modal-extend-duration"
        hide-footer
        size="md"
        :title="'Perpanjang Durasi Expired - ' + display.service_name"
      >
        <div class="row">
          <div class="col-md-12 col-12">
            <b-form-group
              id="input-group-birth-date"
              label="Tanggal Expired Baru:"
              label-for="input-sip-birth-date"
            >
              <b-input-group>
                <div class="row">
                  <div class="col-md-12">
                    <v-dialog
                      ref="dialog"
                      v-model="modal3"
                      :return-value.sync="expired.extend_date"
                      persistent
                      width="350px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <b-input-group>
                          <b-input-group-prepend>
                            <button
                              class="btn btn-secondary"
                              type="button"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <i class="fas fa-calendar"></i>
                            </button>
                          </b-input-group-prepend>
                          <b-form-input
                            id="input-trigger-modal-patient"
                            v-model="expired.extend_date"
                            placeholder="(YYYY-MM-DD)"
                            @input="formaterDateExp"
                          >
                          </b-form-input>
                        </b-input-group>
                      </template>

                      <v-date-picker
                        v-if="modal3"
                        v-model="expired.extend_date"
                        locale="id"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal3 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialog.save(expired.extend_date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </div>
                </div>
              </b-input-group>
            </b-form-group>
          </div>

          <div class="col-md-12 col-12 py-1">
            <div class="d-flex justify-content-end py-1">
              <b-button
                type="button"
                class="ml-2"
                variant="success"
                @click="saveUpdateExpired(expired.expired_item_id)"
              >
                Ubah Tanggal
              </b-button>
            </div>
          </div>
        </div>
      </b-modal>

      <ModalPatient @chosenPatient="setPatient" @submitedPatient="setPatient" />
    </div>
  </v-app>
</template>

<script>
import validation from "@/core/modules/ValidationModule.js";
import module from "@/core/modules/CrudModule.js";
import ModalPaymentCategories from "@/component/masters/payment-categories/ModalPaymentCategoriesAdd.vue";
import ModalPatient from "@/component/general/ModalPatient.vue";
import moment from "moment";

export default {
  components: {
    ModalPatient,
  },

  props: {
    source: Number,
    // Number :
    // 0 = daftar order
    // 1 = per patient (list patient)
  },

  data() {
    return {
      // Filter
      modal2: false,
      modal3: false,
      filter: {
        patient_id: "",
        start_date: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      },
      // display
      display: {
        patient_name: "",
        service_name: "",
        package_name: "",
        usage_total: "",
        remaining_usage: 0,
      },
      dataDetail: [],
      // Pagination
      perPage: 20,
      currentPage: 1,
      lastCurrentPage: 1,
      totalRows: 0,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "buy_date",
          label: "Tgl. Pembelian",
          sortable: true,
        },
        {
          key: "expired_at",
          label: "Tgl. Expired",
          sortable: true,
        },
        {
          key: "patient_name",
          label: "Nama Pasien",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama Layanan",
          sortable: true,
        },
        {
          key: "price",
          label: "Harga",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
        },
        {
          key: "action",
          label: "",
        },
      ],

      // Table Items
      items: [],
      doctors: [],
      nurses: [],

      // filter timeseries
      onShowPeriod: false,
      btnActive: {
        days: true,
        month: false,
        lastMonth: false,
        year: false,
        period: false,
      },
      lastPeriodType: "days",

      // form
      form: {
        id: "",
        payment_detail_bundle_item_id: "",
        doctor_id: [],
        nurse_id: [],
        notes: "",
        used_date: moment().format("YYYY-MM-DD"),
        status: "",
        used_amount: 1,

        // array string data
        nurses: "",
      },
      error: {
        doctor_id: "",
        nurse_id: "",
        used_date: "",
        notes: "",
        used_amount: "",
      },

      // other data
      payment_category_id: "",
      amountData: [],

      // expired
      expired :{
        _method:"PUT",
        extend_date: "",
        expired_item_id:"",
      },

      // User Access
      btn: false,
      btnAddDoctorCommission: false,
    };
  },

  methods: {
    formaterDate(val) {
      if (val.length == 4) {
        this.form.used_date = this.form.used_date + "-";
      }
      if (val.length == 7) {
        this.form.used_date = this.form.used_date + "-";
      }
    },
    
    formaterDateExp(val) {
      if (val.length == 4) {
        this.expired.extend_date = this.expired.extend_date + "-";
      }
      if (val.length == 7) {
        this.expired.extend_date = this.expired.extend_date + "-";
      }
    },

    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.lastCurrentPage = page;
      this.pagination();
    },

    formaterDate(val) {
      if (val.length == 4) {
        this.form.used_date = this.form.used_date + "-";
      }
      if (val.length == 7) {
        this.form.used_date = this.form.used_date + "-";
      }
    },

    async getDoctorAndNurseData(id) {
      let doctor = await module.get(
        `package-paid-x-doctor?packagePaid=${id}&displayType=arr`
      );
      this.form.doctor_id = doctor;
      this.form.oldDoctor = doctor;

      let nurse = await module.get(`package-service-paid/detail/${id}`);
      this.form.nurse_id = JSON.parse(nurse.nurses);
    },

    resetFilter() {
      this.filter.patient_id = "";
      this.filter.patient_id = "";
      this.filter.patient_id = "";
      this.display.patient_name = "";
      this.pagination();
    },

    resetFilterPeriod() {
      this.btnActiveOnClick(this.lastPeriodType);
      this.pagination();
    },

    dataAmountVal(usage_total) {
      this.amountData = [];

      for (let i = 1; i <= usage_total; i++) {
        let obj = { label: i, id: i };

        let clone = { ...obj };
        this.amountData.push(clone);
      }
    },

    changeStatus(id, name, usage_total, paymentId) {
      this.form.id = id;
      this.form.payment_detail_bundle_item_id = id;
      this.display.service_name = name;
      this.display.usage_total = usage_total;

      this.dataAmountVal(usage_total);

      // this.payment_category_id = paymentId
      this.getDoctorOptions(paymentId);
      this.$bvModal.show("modal-status");
    },

    btnShowModalDoctor(data, id) {
      this.display.package_name = data;
      this.form.id = id;
      this.$bvModal.show("modal-doctor");
      this.getDoctorAndNurseData(id);
    },

    async detailItem(id, name) {
      let result = await module.get("used-item-service/detail/" + id);
      this.dataDetail = result;
      this.dataDetail.display_date = moment(this.dataDetail.usage_date).format(
        "DD-MM-YYYY"
      );


      this.display.service_name = name;
      this.display.remaining_usage = this.dataDetail.payment_usage.length;

      this.$bvModal.show("modal-detail");
    },

    async saveDetail() {
      // fill data
      this.form.nurses = JSON.stringify(this.form.nurse_id);

      this.error.used_amount = "";

      let response = await module.submit(
        this.form,
        "package-service-paid/used-item/add"
      );

      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success

        Swal.fire(response.success.title, response.success.message, "success");

        this.form.notes = "";
        this.form.used_date = moment().format("YYYY-MM-DD");
        this.$bvModal.hide("modal-status");
        this.pagination();
        this.hideModalCancel();

        // set validation null
        this.error.doctor_id = "";
        this.error.used_amount = "";
      }
    },
    
    async saveUpdateExpired(id) {
      // fill data
      this.expired['_method'] = "put"

      let response = await module.submit(
        this.expired,
        `payment-details/expired/${id}`
      );

      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.pagination()
        this.$bvModal.hide("modal-extend-duration");
      }
    },

    hideModalCancel() {
      this.form.id = "";
      this.form.doctor_id = [];
      this.form.nurse_id = [];
      this.form.notes = "";
      this.form.used_date = moment().format("YYYY-MM-DD");
      this.form.status = "";
      this.$bvModal.hide("modal-status");
      this.$bvModal.hide("modal-doctor");
    },

    setPatient(value) {
      this.filter.patient_id = value.id;
      this.display.patient_name = value.name;
      this.$bvModal.hide("modal-patient");
    },

    async pagination() {
      if (this.source == 1) {
        this.filter.patient_id = this.$route.params.id;
      }

      let filterParams = `&patient_id=${this.filter.patient_id}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      let response = await module.paginate(
        "package-service-paid",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }
      this.items = response.data;
    },

    async getDoctorOptions(id) {
      let route;
      // route = "doctors?payment_category_id="+id;
      route = "doctors";
      let response = await module.setDoctorTreeSelect(route);
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.doctors = response.data;
        this.doctors.unshift({
          label: "Pilih Dokter",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getNurseOptions() {
      let route;
      route = "employees";
      let response = await module.setTreeSelect(route);
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.nurses = response.data;
        this.nurses.unshift({
          label: "Pilih Perawat",
          id: "",
          isDisabled: true,
        });
      }
    },

    // filterByName() {
    //   this.pagination();
    // },

    filterByName() {
      this.currentPage = 1;
      if (this.filter.name == "") {
        this.currentPage = this.lastCurrentPage;
      }
      this.pagination();
    },

    setActiveButton() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "6009") {
          this.btn = true;
        }
        if (access_right[a] == "6023") {
          this.btnAddDoctorCommission = true;
        }
      }
    },

    btnPeriodeOnClick() {
      if (this.onShowPeriod == false) {
        this.onShowPeriod = true;
        this.btnActiveOnClick("period");
      } else {
        this.onShowPeriod = false;
        this.btnActiveOnClick("period");
      }
    },

    addDuration(item) {
      
      this.expired.extend_date = item.expired_notformated;
      this.expired.expired_item_id = item.id
      this.display.service_name = item.name;
      this.$bvModal.show("modal-extend-duration");
    },

    btnActiveOnClick(type) {
      if (type == "days") {
        // set time
        this.filter.start_date = moment()
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = true;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "days";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "month") {
        // set time
        this.filter.start_date = moment()
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = true;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "month";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "lastMonth") {
        // set time
        this.filter.start_date = moment()
          .subtract(1, "months")
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .subtract(1, "months")
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = true;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "lastMonth";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "year") {
        // set time
        this.filter.start_date = moment()
          .startOf("year")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("year")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = true;
        this.btnActive.period = false;

        this.lastPeriodType = "year";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "period") {
        // set time
        this.filter.start_date = "";
        this.filter.end_date = "";

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = true;
      }
    },
  },

  mounted() {
    // Get Data
    this.pagination();
    this.setActiveButton();
    // this.getDoctorOptions();
    this.getNurseOptions();
  },
};
</script>

<style>
.bg-yellow {
  /* background-color: #fcf8e3; */
  background-color: #fffbf0;
}
</style>